import { Alert, Button, Form, message } from "antd";
import React, { useEffect, useState } from "react";
import Inputselect from "../../../components/inputselect/inputselect";
import {
  getBeneficiaries,
  getWalletsDetails,
  setupAutoPayout,
} from "../../../api/wallet/wallets";
import {
  commaNumber,
  openNotificationWithIcon,
  transformCurrencyToSymbol,
} from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  updateReloadWallet,
  updateUser,
} from "../../../store/userSlice/userSlice";
import { RootState } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import Inputamount from "../../../components/inputamount/inputamount";
import TransactionPin from "../../../components/transactionpin/transactionpin";
import { useEnabledTransactionPin } from "../../../customhooks/useEnabledTransactionPin";

const enableOptions = [
  {
    name: "Yes",
    value: "Yes",
  },
  {
    name: "No",
    value: "No",
  },
];

const Autopayout = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasPinEnabled = useEnabledTransactionPin();

  const [loadingBeneficiaries, setLoadingBeneficiaries] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState<any>([]);
  const [wallets, setWallets] = useState<any[]>([]);
  const [loadingWallets, setLoadingWallets] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    currency: "",
    bank_account_id: "",
    limit: "",
    enable: "",
  });

  const [paymentSummaryDetails, setPaymentSummaryDetails] = useState<
    {
      title: string;
      value: any;
    }[]
  >([]);
  const [detailsToBeSubmitted, setDetailsToBeSubmitted] = useState<any | null>(
    null
  );
  const [showTransactionPin, setShowTransactionPin] = useState(false);

  const closeTransactionPin = () => {
    if (!loading) {
      setShowTransactionPin(false);
      setPaymentSummaryDetails([]);
      setDetailsToBeSubmitted(null);
    }
  };

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    reloadWallet: state.user.reloadWallet,
  }));

  const { user } = state;

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
    if (name === "wallet_id") {
      let findWallet = wallets.filter((item) => item?.wallet_id === value);
      form.setFieldValue("currency", findWallet?.[0]?.currency);
			if(findWallet?.[0]?.auto_transfer_details?.length ) {
				form.setFieldsValue({
					// bank_account_id: findWallet?.[0]?.auto_transfer_details?.[0]?.reference_code,
					limit: findWallet?.[0]?.auto_transfer_details?.[0]?.limit,
					enable: findWallet?.[0]?.auto_transfer_details?.[0]?.enabled,
				});
			} else {
				form.setFieldsValue({
					bank_account_id: undefined,
					limit: undefined,
					enable: undefined,
				});
			}

    }
  };

  const constructPaymentSummaryDetails = (values: {
    enable: string;
    limit: string;
    bank_account_id: string;
    wallet_id: string;
  }) => {
    const incomingBeneficiary = beneficiaries.filter(
      (item: any) => item.recipient_id === values.bank_account_id
    );

    const detail = [
      {
        title: "Limit",
        value: `${transformCurrencyToSymbol(
          incomingBeneficiary[0]?.currency
        )}${commaNumber(values.limit)}`,
      },
      {
        title: "Account Number",
        value: incomingBeneficiary[0]?.number,
      },
      {
        title: "Bank",
        value: incomingBeneficiary[0]?.bank,
      },
      {
        title: "Name",
        value: incomingBeneficiary[0]?.name,
      },
      {
        title: "Enable Auto payout",
        value: values?.enable,
      },
    ];

    setPaymentSummaryDetails(detail);
  };

  const followThroughAfterPin = async (otp: string) => {
    try {
      setError(null);
      setLoading(true);

      delete detailsToBeSubmitted.wallet_id;

      const res = await setupAutoPayout({
				...detailsToBeSubmitted,
				authorization_pin: otp,
      });

      const { status, message, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Auto Payout", message);

        form.resetFields();
        setPaymentSummaryDetails([]);
        setShowTransactionPin(false);
        setDetailsToBeSubmitted(null);

        getWallet();
      } else {
        setError(message);
        openNotificationWithIcon("error", "Auto Payout", message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Auto Payout");
    }
  };

  const onFinish = async (values: any) => {
    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        let dataToSend = {
          currency: form.getFieldValue("currency"),
          ...values,
        };

        setDetailsToBeSubmitted(dataToSend);

        if (hasPinEnabled) {
          constructPaymentSummaryDetails(dataToSend);
          setLoading(false);
          setShowTransactionPin(true);
          return false;
        }

        delete dataToSend.wallet_id;

        const res = await setupAutoPayout({
          ...dataToSend,
        });

        const { status, message } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Auto Payout", message);

          form.resetFields();

          getWallet();
        } else {
          setError(message);
          openNotificationWithIcon("error", "Auto Payout", message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Something went wrong: Auto Payout");
      }
    } else {
      setLoading(false);
    }
  };

  const getWallet = async () => {
    setLoadingWallets(true);
    try {
      const res = await getWalletsDetails();

      const { status, data } = res.data;

      if (status === "success") {
        let newWallets: any = [];
        data.forEach((item: any) => {
          newWallets.push({
            ...item,
            walletLabel: `${item?.currency.toUpperCase()} WALLET - ${transformCurrencyToSymbol(
              item?.currency
            )} ${commaNumber(parseFloat(item?.available_balance).toFixed(2))}`,
          });
        });

        setWallets(newWallets);

        dispatch(
          updateUser({
            user: { ...user, wallets: data },
          })
        );

        dispatch(
          updateReloadWallet({
            reloadWallet: false,
          })
        );
      } else {
        message.warning(`Get wallets, ${res.data.message}`);
      }

      setLoadingWallets(false);
    } catch (error) {
      setLoadingWallets(false);
      message.error("Something went wrong: Get wallets");
    }
  };

  useEffect(() => {
    getWallet();

    // eslint-disable-next-line
  }, []);

  const fetchBeneficiaries = async () => {
    setLoadingBeneficiaries(true);
    try {
      const res = await getBeneficiaries({
        currency: form.getFieldValue("currency") || "",
        beneficiaryType: "External",
      });

      const { status, message, data } = res.data;

      if (status === "success") {
        let newBeneficiaries: any[] = [];
        data.forEach((item: any) => {
          newBeneficiaries.push({
            ...item,
            beneficiaryLabel: `${item?.name} - ${item?.bank} ${item?.number}`,
          });
        });
        setBeneficiaries(newBeneficiaries);
				
				let findWallet = wallets.filter((item) => item?.wallet_id === form.getFieldValue("wallet_id"))

				if(findWallet?.[0]?.auto_transfer_details?.length ) {
					form.setFieldsValue({
						bank_account_id: findWallet?.[0]?.auto_transfer_details?.[0]?.bank_account_id,
					});
				}
				
      } else {
        openNotificationWithIcon("error", "Beneficiaries", message);
      }

      setLoadingBeneficiaries(false);
    } catch (error) {
      setLoadingBeneficiaries(false);
      message.error("Something went wrong: Beneficiaries");
    }
  };

  useEffect(() => {
    if (form.getFieldValue("wallet_id") !== undefined) {
      fetchBeneficiaries();
    }
  }, [form.getFieldValue("wallet_id")]);

  return (
    <div className="mt-4">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Auto Payout
          </h1>
        </div>

        <div className="pb-16">
          {error != null && (
            <div className="flex justify-center mb-4">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}

          <Form onFinish={onFinish} layout="vertical" form={form}>
            <Form.Item
              validateStatus={errors?.currency.length > 0 ? "error" : undefined}
              help={errors?.currency.length > 0 ? errors.currency : undefined}
              label="Wallet"
              name="wallet_id"
              rules={[
                {
                  required: true,
                  message: "Wallet is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Wallet"
                updateField={updateField}
                name={"wallet_id"}
                selectOptions={wallets}
                loading={loadingWallets}
                selectOptionLabel="walletLabel"
                alternativeValue="wallet_id"
                selectValue={form.getFieldValue("wallet_id")}
              />
            </Form.Item>

            <>
              <Form.Item
                validateStatus={
                  errors?.bank_account_id.length > 0 ? "error" : undefined
                }
                help={
                  errors?.bank_account_id.length > 0
                    ? errors.bank_account_id
                    : undefined
                }
                label="Beneficiary"
                name="bank_account_id"
                rules={[
                  {
                    required: true,
                    message: "Wallet is required",
                  },
                ]}
              >
                <Inputselect
                  placeHolder="Select Beneficiary"
                  updateField={updateField}
                  name={"bank_account_id"}
                  selectOptions={beneficiaries}
                  loading={loadingBeneficiaries}
                  selectOptionLabel="beneficiaryLabel"
                  alternativeValue="recipient_id"
                  disabled={form.getFieldValue("currency") === undefined}
                  showSearch={true}
									selectValue={form.getFieldValue("bank_account_id")}
                />
              </Form.Item>
              <p
                onClick={() => [navigate("/dashboard/beneficiary")]}
                className="mb-4 cursor-pointer text-appcolorblue w-fit"
              >
                Create Beneficiary
              </p>
            </>

            <Form.Item
              validateStatus={errors?.limit.length > 0 ? "error" : undefined}
              help={errors?.limit.length > 0 ? errors.limit : undefined}
              label="Limit"
              name="limit"
              rules={[
                {
                  required: true,
                  message: "Limit is required",
                },
              ]}
            >
              <Inputamount
                name="limit"
                updateField={updateField}
                placeHolder="Enter limit"
                currency={transformCurrencyToSymbol(
                  form.getFieldValue("currency")
                )}
                amountValue={form.getFieldValue("limit")}
                disabled={form.getFieldValue("currency") === undefined}
              />
            </Form.Item>

            <Form.Item
              validateStatus={errors?.enable.length > 0 ? "error" : undefined}
              help={errors?.enable.length > 0 ? errors.enable : undefined}
              label="Enable Automatic Payment"
              name="enable"
              rules={[
                {
                  required: true,
                  message: "Field is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Option"
                updateField={updateField}
                name={"enable"}
                selectOptions={enableOptions}
                loading={false}
                selectOptionLabel="name"
                alternativeValue="value"
                selectValue={form.getFieldValue("enable")}
                disabled={form.getFieldValue("currency") === undefined}
              />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                className="mb-2 "
                disabled={loading || loadingBeneficiaries || loadingWallets}
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      {showTransactionPin && (
        <TransactionPin
          paymentType="auto payment"
          visible={showTransactionPin}
          closeModal={closeTransactionPin}
          loading={loading}
          paymentDetails={paymentSummaryDetails}
          onSubmit={followThroughAfterPin}
        />
      )}
    </div>
  );
};

export default Autopayout;
